.page {
  min-height         : 900px;
  height             : 100vh;
  background-image   : linear-gradient(rgba(0, 0, 0, 0.71), rgba(0, 0, 0, 0.71)), url(./images/bg.jpg);
  // background-size: 203vh;
  background-position: top;
  display            : flex;
  flex-direction     : column;
  justify-content    : center;

}

.modal {
  // <div style={{height:'300px', width:'300px',backgroundColor:'#ffffff', top:'50%', right:'50%',position:'absolute', border: '2px solid #000'}}>
  height          : 50%;
  width           : 50%;
  background-color: #ffffff;
  top             : 25%;
  right           : 25%;
  position        : absolute;
  border          : 2px solid #000;
  font-size       : 20px;
  padding         : 10px;
  text-align      : center;
  justify-content : center;
  align-items     : center;
  display         : flex;
  flex-direction  : column;
}

.modalText {
  color      : #000;
  font-family: 'Open Sans';
  width      : 50%;

}
.buttonLogo {
  width      : 15px;
  margin-left: 2px;
}

.Button {
  // font-size: 20px;
  font-weight                   : 500;
  padding                       : 0.3em 1em;
  line-height                   : 1.7em !important;
  // background-color: transparent;
  background-size               : cover;
  background-position           : 50%;
  background-repeat             : no-repeat;
  border                        : 2px solid;
  border-radius                 : 3px;
  text-decoration               : none;
  // -webkit-transition-duration: .2s;
  transition-duration           : .2s;
  // -webkit-transition-property: all!important;
  transition-property           : all !important;
  margin                        : 15px;
}

.Button:hover {
  border-color: transparent;
}

.content {
  display        : flex;
  flex-direction : column;
  justify-content: center;
  text-align     : center;
  align-items    : center;
  color          : #ffffff;
}

.logo {
  top            : 100px;
  display        : flex;
  align-items    : center;
  justify-content: center;

  .logoImg {
    width        : 400px;
    margin-top   : 10px;
    margin-bottom: 20px;
    transition   : .2s;
  }

  .logoImg:hover {
    width: 402px;
  }
}

.click {
  cursor: pointer;
}

.buttonLogo {
  width      : 15px;
  margin-left: 2px;
}

.button {
  // font-size: 20px;
  font-weight                   : 500;
  padding                       : 0.3em 1em;
  line-height                   : 1.7em !important;
  // background-color: transparent;
  background-size               : cover;
  background-position           : 50%;
  background-repeat             : no-repeat;
  border                        : 2px solid;
  border-radius                 : 3px;
  text-decoration               : none;
  // -webkit-transition-duration: .2s;
  transition-duration           : .2s;
  // -webkit-transition-property: all!important;
  transition-property           : all !important;
  margin                        : 15px;
}

.button:hover {
  border-color: transparent;
}

.mint {
  font-size       : 25px;
  font-weight     : bold;
  background-color: #ffffff;
  color           : #000000;
  opacity         : 0.6;
  border-color    : transparent;
}

.mint:hover {
  border: white;

}

.buttonContainer {

  padding-top   : 15px;
  padding-bottom: 15px;
}

.Minus {
  padding: 0.3em 1.13em;
}

a {
  color          : #ffffff;
  text-decoration: none;

}

body {
  background-color: #ffffff;
  font-family     : 'Open Sans';
}

.connect {
  width: 150px
}

// .discord{
//   // padding: 0.5em 1em;
// }
.message {
  font-size: 30px;
  margin   : 10px;
}

.opensea {
  height: 30px;
}

.logoButton {
  display        : flex;
  justify-content: center;
  align-items    : center;
}

@media only screen and (max-width:768px) {
  .message {
    font-size: 25px;
  }

}

@media only screen and (max-width:425px) {
  .logo {
    .logoImg {
      width: 90vw;
    }
  }

  .button {
    margin: 10px;
  }

  .buttonContainer {
    padding-top   : 10px;
    padding-bottom: 10px;
  }

  .message {
    font-size: 20px;
  }

  .modal {
    font-size: 15px;
  }
}

@media only screen and (max-width:360px) {
  .mint {font-size: 18px;}
}